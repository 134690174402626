import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import Button from '../components/button'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Text, { typography } from '../components/text'
import { global } from '../styles/global'
import { format } from '../utils/date'
import { usePageContext } from '../context/page-context'
import Link from '../components/link'

const ArticleTemplate = ({ data: { article, settings }, location, }) => {
  const relatedNews = location?.state?.relatedNews
    ?.filter((item) => item.headline != article.headline)
    ?.slice(0, 3)

  const relatedNewsLabel = location?.state?.relatedNewsLabel

  const { lang } = usePageContext()

  return (
    <Layout pageTags={article.seoMetaTags} noIndex={article.noIndex}>
      <div>
        <header
          css={[
            tw`pt-8 pb-20 bg-secondary-500 bg-opacity-5 lg:py-32`,
            article.image && tw`pb-36 lg:pb-64`,
          ]}
        >
          <div css={[global`layout.container`, global`layout.grid`]}>
            <div css={tw`col-span-4 md:col-span-8 lg:col-span-2 xl:col-span-3`}>
              <Button
                type="primary-reverse"
                size="sm"
                label={settings.backLabel}
                link="/news"
                theme="transparent-transparent"
                style={css`
                  ${tw`mb-10 lg:(-mt-3 mb-0)`}
                  span {
                    ${tw`m-0`}
                  }
                  svg {
                    ${tw`mr-3`}
                  }
                `}
              />
            </div>
            <div
              css={tw`flex flex-col items-start col-span-4 pl-6 md:col-span-8 lg:(pl-0 col-span-8) xl:col-span-10`}
            >
              <div
                css={[
                  global`typography.subtitle`,
                  tw`block mb-10 font-bold capitalize text-primary-500 text-opacity-47 lg:mb-16`,
                ]}
              >
                {article.typeOfArticle ? `${article.typeOfArticle} - ` : undefined}
                <time>{format(article.date, lang == 'en' ? 'dd MMMM yyyy' : 'yyyy/MM/dd')}</time>
              </div> 
              <Heading
                content={article.headline}
                headingType="h1"
                style={tw`font-bold text-primary-500`}
              />
              {article.subheading && (
                <Heading content={article.subheading} headingType="h5" style={tw`mt-6`} />
              )}
            </div>
          </div>
        </header>
        <section
          css={[global`layout.container`, global`layout.grid`, tw`pt-20 pb-24 lg:(pt-32 pb-40)`]}
        >
          {article.image && (
            <div
              css={tw`col-span-4 md:col-span-8 -mt-36 mb-16 -ml-container-mobile lg:(-mt-64 mb-32) xl:(col-start-4 col-span-10)`}
            >
              <Image image={article.image} />
            </div>
          )}
          <Text
            content={article.contentNode}
            style={[
              typography,
              global`typography.body-md`,
              tw`col-span-4 md:col-span-8 lg:(col-start-3 col-end-11) xl:(col-start-4 col-end-14)`,
            ]}
          />
        </section>
        {relatedNews && (
          <section css={[tw`bg-primary-50 pt-20 pb-34.5`]}>
            <div css={[global`layout.container`, tw`lg:(flex flex-row flex-wrap justify-between)`]}>
              <div css={tw`w-full`}>
                <Heading
                  content={relatedNewsLabel}
                  headingType="h2"
                  style={tw` font-normal text-primary-500 mb-12 lg:(text-4xl mb-18)`}
                />
              </div>
              {relatedNews &&
                relatedNews.map((item, index) => (
                  <div
                    key={index}
                    css={tw`flex flex-col items-start flex-none w-4/5 mt-8  md:w-11/20
                lg:(mx-0 w-5/12 border-r last:border-r-0) 
                xl:w-96 xxl:w-116`}
                  >
                    <Link to={item.callToAction.link}>
                      <div css={tw`flex flex-col items-start`}>
                        <div
                          css={[
                            global`typography.subtitle`,
                            tw`mb-5 font-bold text-primary-500 text-opacity-47`,
                          ]}
                        >
                          {article.typeOfArticle ? `${article.typeOfArticle} - ` : undefined}
                          <time>{format(article.date, 'dd MMMM yyyy')}</time>
                        </div>

                        <Heading content={item.headline} headingType="h4" style={tw`font-bold`} />

                        <Text style={tw`mt-6 text-primary-500 text-base`} content={item.excerpt} />
                      </div>

                      <Button
                        style={css`
                          ${tw`mt-12 bg-transparent text-primary-500`}
                          span {
                            ${tw`ml-0`}
                          }
                          svg {
                            ${tw`text-primary-500 lg:(transition-transform duration-300 ease-in-out)`}
                          }
                        `}
                        type="primary"
                        size="sm"
                        label={item.callToAction.label}
                      />
                    </Link>
                  </div>
                ))}
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  }),
}

export default ArticleTemplate

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    article: datoCmsPressRelease(slug: { eq: $slug }, locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      typeOfArticle
      noIndex
      headline
      subheading
      date
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    settings: datoCmsHkGiNews(locale: { eq: $locale }) {
      backLabel
    }
  }
`
